import React, { Component } from "react";
import { FaInstagram, FaFacebookF, FaWhatsapp } from "react-icons/fa";
import "../footer/footer.css";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const SocialShare = [
  {
    Social: <FaFacebookF />,
    link: "https://www.facebook.com/people/Zeynel-Hotel/100063681516567/",
  },

  {
    Social: <FaInstagram />,
    link: "https://www.instagram.com/zeynelhotel/",
  },
  {
    Social: <FaWhatsapp />,
    link: "https://api.whatsapp.com/send/?phone=%2B905526170755&text=++Merhaba+%21+++&type=phone_number&app_absent=0",
  },
];

class Footer extends Component {
  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <footer>
          <div>
            <div className="row align-items-end ">
              <div className="col-lg-12">
                <div
                  className="footer-right  bg_image--35 "
                  data-black-overlay="7"
                >
                  <div className="row">
                    {/* Start Single Widget  */}
                    <div className="col-lg-6 col-sm-6 col-12">
                      <div className="footer-link">
                        <h4 style={{ textAlign: "center", cursor: "default" }}>
                          ZEYNEL HOTEL
                        </h4>
                        <ul
                          style={{
                            listStyle: "none",
                            color: "grey",
                            fontSize: "18px",
                            padding: "0px",
                          }}
                        >
                          <div className="footermoilfirst footermoilsecond">
                            <li>
                              <a href="/">{t("home")}</a>
                            </li>
                            <li>
                              <Link to="/aboutus">
                                <p style={{ fontSize: "18px" }}>
                                  {t("aboutus")}
                                </p>
                              </Link>
                            </li>
                            <li>
                              <a href="/gallery">{t("galery")}</a>
                            </li>

                            <li>
                              <a href="/contact">{t("contact")}</a>
                            </li>
                          </div>
                        </ul>
                      </div>
                    </div>
                    {/* End Single Widget  */}
                    {/* Start Single Widget  */}
                    <div
                      className="col-lg-6 col-sm-6 col-12 mt_mobile--30"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div className="footer-link">
                        <h4
                          style={{
                            textAlign: "left",
                            justifyContent: "center",
                            cursor: "default",
                          }}
                        >
                          {t("contact")}
                        </h4>
                        <ul
                          style={{
                            listStyle: "none",
                            color: "grey",
                            fontSize: "18px",
                            padding: "0px",
                            justifyContent: "center",
                            textAlign: "left",
                          }}
                        >
                          <li>
                            <a href="mailto:info@zeynelhotel.com">
                              info@zeynelhotel.com
                            </a>
                          </li>
                          <li>
                            <a
                              href="tel:+90 (242) 334 07 55
"
                            >
                              +90 (242) 334 07 55
                            </a>
                          </li>
                          <li>
                            <a href="/">
                              Arapsuyu Mahallesi 610 Sokak <br /> No: 9
                              Konyaaltı / Antalya / Türkiye
                            </a>
                          </li>
                        </ul>

                        <div className="social-share-inner">
                          <ul className="social-share social-style--2 d-flex justify-content-center liststyle mt--15">
                            {SocialShare.map((val, i) => (
                              <li key={i}>
                                <a href={`${val.link}`}>{val.Social}</a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 pt--20">
                      <div
                        style={{
                          textAlign: "center",
                          fontWeight: 400,
                          color: "grey",
                        }}
                      >
                        <p>
                          Copyright © 2023
                          <span>
                            <a
                              href="https://www.stmedya.net/"
                              target="_blank"
                              style={{
                                textDecoration: "none",
                                fontWeight: 600,
                              }}
                            >
                              &nbsp; ST MEDYA
                            </a>
                          </span>
                          .&nbsp; {t("allrights")}
                        </p>
                      </div>
                    </div>
                    {/* <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                      <iframe
                        title="google-maps"
                        width="100%"
                        height="500"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d895.1646119969218!2d30.636356771485406!3d36.91417874801298!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c38ff319c02803%3A0x84f6462c2670e5fc!2sAkdeniz%20Konsept!5e0!3m2!1str!2str!4v1680616376044!5m2!1str!2str"
                      ></iframe>
                    </div> */}

                    {/* End Single Widget  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}
export default withTranslation()(Footer);
